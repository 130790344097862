import React from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";
import { Button, ButtonGroup } from "clutch/src/Button/Button.jsx";
import { mobileMedium } from "clutch/src/Style/style.mjs";

import { readState } from "@/__main__/app-state.mjs";
import { appURLs } from "@/app/constants.mjs";
import AccountsLol from "@/hub-settings/AccountsLol.jsx";
import { updateTftSkinSettings } from "@/hub-settings/actions.mjs";
import {
  PageContainer,
  Panel,
  SettingsContent,
} from "@/hub-settings/Common.style.jsx";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default function TFTSettings() {
  const { t } = useTranslation();
  const state = useSnapshot(readState);
  const isSkinsEnabled = state.settings.tft.isSkinsEnabled;
  const handleSkinFeature = (boolean) => () => updateTftSkinSettings(boolean);
  const championSkinsTranslation = t(
    "common:settings.tft.championSkinsTitle",
    "Use Skin Artwork for Champion Portraits",
  );
  const championSkinsImage = `${
    appURLs.CDN
  }/blitz/tft/settings/settingsTftSkinSettings${
    isSkinsEnabled ? "" : "Disabled"
  }.png`;
  return (
    <PageContainer>
      <AccountsLol game="tft" />
      <Panel
        className={css`
          flex-direction: row !important;

          ${mobileMedium} {
            flex-direction: column !important;
          }
        `}
      >
        <img
          alt={championSkinsTranslation}
          src={championSkinsImage}
          width="440px"
          height="264px"
          className={css`
            width: 220px;
          `}
        />
        <SettingsContent
          className={css`
            display: flex;
            flex-direction: column;
            gap: var(--sp-3);
          `}
        >
          <h5 className="type-article-headline">{championSkinsTranslation}</h5>
          <div
            className={[
              "type-caption",
              css`
                color: var(--shade2);
              `,
            ].join(" ")}
          >
            {t(
              "common:settings.tft.championSkinsSubtitle",
              "Use TFT set skin artwork when displaying/representing champion images.",
            )}
          </div>
          <ButtonGroup>
            <Button onClick={handleSkinFeature(true)} active={isSkinsEnabled}>
              {t("common:enabled", "Enabled")}
            </Button>
            <Button onClick={handleSkinFeature(false)} active={!isSkinsEnabled}>
              {t("common:disabled", "Disabled")}
            </Button>
          </ButtonGroup>
        </SettingsContent>
      </Panel>
    </PageContainer>
  );
}
